import React from 'react'
import styles from './styles.module.css'

/**
 * Modal Component
 * @returns {React.Component} Modal .
 */
const Modal = ({ data, country, close, confirm }) => (
  <div className={styles.ModalContainer} onClick={() => close()}>
    <div className={styles.Wrapper}>
      <div className={styles.Title}>Verifica tus datos</div>
      <div className={styles.SubTitle}>
        Una vez que enviamos esta información se realizará tu deposito en 15 días
        hábiles máximo, es importante que verifiques la información de depósito
      </div>
      <div className={[styles.Wave, styles.WaveTop].join(' ')} />
      <div className={styles.TicketContainer}>
        <div className={styles.Item}>
          <p className={styles.Key}>Nombre</p>
          <p className={styles.Value}>{data.name}</p>
        </div>
        <div className={styles.Item}>
          <p className={styles.Key}>Banco</p>
          <p className={styles.Value}>{data.bank}</p>
        </div>
        <div className={styles.Item}>
          <p className={styles.Key}>Número de cuenta Bancaria</p>
          <p className={styles.Value}>{data.accountNum}</p>
        </div>
        {country === 'México' && (
          <div className={styles.Item}>
            <p className={styles.Key}>CLABE</p>
            <p className={styles.Value}>{data.clabe}</p>
          </div>
        )}
        {country === 'Colombia' && (
          <>
            <div className={styles.Item}>
              <p className={styles.Key}>Tipo de cuenta</p>
              <p className={styles.Value}>{data.accountType}</p>
            </div>
            <div className={styles.Item}>
              <p className={styles.Key}>Tipo de Identificación</p>
              <p className={styles.Value}>{data.identiType}</p>
            </div>
            <div className={styles.Item}>
              <p className={styles.Key}>Número de Identificación</p>
              <p className={styles.Value}>{data.identiNum}</p>
            </div>
          </>
        )}
        {country === 'Chile' && (
          <>
            <div className={styles.Item}>
              <p className={styles.Key}>Tipo de cuenta</p>
              <p className={styles.Value}>{data.accountType}</p>
            </div>
            <div className={styles.Item}>
              <p className={styles.Key}>Tipo de Identificación</p>
              <p className={styles.Value}>{data.identiType}</p>
            </div>
            <div className={styles.Item}>
              <p className={styles.Key}>Número de Identificación</p>
              <p className={styles.Value}>{data.identiNum}</p>
            </div>
          </>
        )}
        {country === 'Peru' && (
          <>
            <div className={styles.Item}>
              <p className={styles.Key}>Código de cuenta interbancario</p>
              <p className={styles.Value}>{data.clabe}</p>
            </div>
            <div className={styles.Item}>
              <p className={styles.Key}>Tipo de cuenta</p>
              <p className={styles.Value}>{data.accountType}</p>
            </div>
            <div className={styles.Item}>
              <p className={styles.Key}>Tipo de Identificación</p>
              <p className={styles.Value}>{data.identiType}</p>
            </div>
            <div className={styles.Item}>
              <p className={styles.Key}>Documento de identidad</p>
              <p className={styles.Value}>{data.identiNum}</p>
            </div>
          </>
        )}
      </div>
      <div className={styles.SubmitButton} onClick={() => confirm()}>
        Si
      </div>
      <div className={styles.ToCorrect} onClick={() => close()}>
        No
      </div>
    </div>
  </div>
)

export default Modal

import React from 'react'
import Header from '../Header/styles'

/**
 * Shell component that contains Header and children elements
 * @param {Component} children - Components to render inside shell
 * @returns {void} .
 */
const Shell = ({ children }) => (
  <>
    <Header />
    {children}
  </>
)

export default Shell

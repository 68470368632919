import React from 'react'
import styles from './styles.module.css'
import Shell from '../../Components/Shell'
import Button from '../../Components/Button'
import { useHistory } from 'react-router-dom'

/**
 * Succes page view.
 * @returns {void} .
 */
const Success = () => {
  const history = useHistory()
  const { country } = history.location.state

  let link = 'mx'

  switch (country) {
  case 'México':
    link = 'mx'
    break
  case 'Colombia':
    link = 'co'
    break
  case 'Chile':
    link = 'cl'
    break
  case 'Peru':
    link = 'pe'
    break
  default:
    link = 'mx'
  }

  const imgApolo = window.webpSupported
    ? require('../../Assets/images/webp/apollo-corner.webp')
    : require('../../Assets/images/jpg/apollo-corner.jpg')

  return (
    <Shell>
      <div className={styles.SuccessContainer}>
        <div className={styles.ImageContainer}>
          <img src={imgApolo} alt="apolo" />
        </div>
        <div className={styles.SuccessTitle}>
          Se registro exitosamente tu orden de devolución
        </div>
        <div className={styles.Description}>
          Nuestros agentes de finanzas te contactaran lo mas pronto posible
        </div>
        <div className={styles.ButtonContainer}>
          <Button link={`https://mymoons.${link}`}>Ir a Moons</Button>
        </div>
      </div>
    </Shell>
  )
}
export default Success

import React from 'react'
import moon from '../../Assets/images/png/lunafooter.png'
import ring from '../../Assets/images/png/path.png'
import styles from './styles.module.css'

/**
 * Loader Full Screen component
 * @return {void}
 */
const LoaderFullScreen = ({ message }) => (
  <div className={styles.Container}>
    <div className={styles.SealRing}>
      <img src={ring} alt="Animacion" />
      <div className={styles.SealMoon}>
        <img src={moon} alt="Moons" />
      </div>
    </div>
    <div className={styles.ContainerMessage}>{message}</div>
  </div>
)

export default LoaderFullScreen

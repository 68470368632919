import React from 'react'
import { NavLink } from 'react-router-dom'
import styles from './styles.module.css'

/**
 * Button component.
 * @param {string} link - Link to a new page in other tab
 * @param {string} to - Link to other page view
 * @param {string} children - text inside component
 * @param {event} event - event that tringen when something happend
 * @param {boolean} inverted - Indicate styles in component
 * @returns {void} .
 */
const Button = ({ link, to, children, event, inverted }) => {
  const style = inverted ? styles.Inverted : styles.Button
  if (link) {
    return (
      <a href={link} className={style} onClick={event}>
        {children}
      </a>
    )
  }
  if (to) {
    return (
      <NavLink to={to} className={style} onClick={event}>
        {children}
      </NavLink>
    )
  }
  return (
    <button type="button" className={`${style} ${styles.Height}`} onClick={event}>
      {children}
    </button>
  )
}

export default Button

export const EMPTY_STRING = ''

export const SELECT_BANK = 'Selecciona un Banco'

export const SELECT_ACCOUNT_TYPE = 'Selecciona un tipo de cuenta'

export const SELECT_IDENTIFICACITON_TYPE =
  'Selecciona un tipo de identificación'

export const ERROR_ALPHANUMERIC =
  'Ingresa tu número de indentificación con caracteres alfanumericos'

export const ERROR_NUMERIC =
  'Ingresa tu número de indentificación unicamente con numeros'

export const ERROR_IDENTIFICATION_NUMBER =
  'Ingresa tu número de indentificación'

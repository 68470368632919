/* eslint-disable max-statements */
/* eslint-disable import/prefer-default-export */
import axios from 'axios'

/**
 * @param {Object} data -.
 * @param {String} data.customerId -. the customerId of the patient
 * @returns {{transactionLog: Object, error: string}} -.
 */
export const updateTransactionLog = async ({customerId }) => {
  const api = process.env.REACT_APP_REFUND_API
  const url = `${api}/update-validated-to-be-refund`
  const body = {
    customerId
  }
  try {
    const response = await axios.post(url, body)
    const { data } = response
    const { updated } = data
    return { transactionLog: updated, error: null }
  } catch (error) {
    let message = error.message ? error.message : 'Error al intentar actualizar TransactionLog'
    if (error.response) {
      const errorResponse = error.response
      const errorData = error.response.data
      message = errorData.message ? errorData.message : message
    }
    return { transactionLog: null, error: message }
  }
}

import React from 'react'
import styles from './styles.module.css'

/**
 * Title component.
 * @param {string} children - text inside h1 element
 * @returns {void} .
 */
const Title = ({ children }) => <h1 className={styles.Title}>{children}</h1>

export default Title

/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react'
import styles from './styles.module.css'

/**
 * Form Group component
 * @param {Object} data -.
 * @param {string} data.label - label of the input
 * @param {boolean} data.showError - true if there was an error
 * @param {string} data.error - text to show when error occured
 * @param {Event} data.onChange - event that execute when acction happen
 * @param {number} data.maxLength - number that indicate th max length in input
 * @param {string} data.type - type of input (text,number,email)
 * @param {Boolean} data.isNumber - check if only numbers is allowed
 * @return {void}
 */
const Input = ({
  label,
  showError,
  error,
  onChange,
  maxLength,
  type,
  isNumber,
  value,
}) => {
  /**
   * @param {Event} event - .
   * execute function sending the info in the input
   * @return {Void} .-
   */
  const handleChange = (event) => {
    if (isNumber) {
      const re = /^[0-9\b]+$/
      if (event.target.value === '' || re.test(event.target.value)) {
        onChange(event.target.value)
      }
    } else {
      onChange(event.target.value)
    }
  }

  return (
    <div className={styles.Form}>
      <label className={styles.Inp}>
        <input
          type={type}
          maxLength={maxLength}
          onChange={handleChange}
          value={value}
          placeholder="&nbsp;"
        />
        <span className={styles.Label}>{label}</span>
        <span className={styles.Border} />
      </label>
      {showError && <label className={styles.Error}>{error}</label>}
    </div>
  )
}

export default Input

/* eslint-disable no-process-env */
/* eslint-disable import/prefer-default-export */
/* eslint-disable implicit-arrow-linebreak */
import { callApi } from '../util/Requests'

/**
 * funtion to make a request to BlackBox sending a custom JSON
 * @param {string} phone - element to search in BlackBox
 * @param {string} email - element to search in BlackBox
 * @param {Object} data -
 * {Bank_Name,Bank_Account_Number,CLABE,Type_Bank_Account, Identifier_Type,Identifier_Number}
 * @returns {Object} BlackBox Result Object
 */
export const updatePatientBlackBox = (phone, email, data, urlTrhidParty) =>
  callApi(process.env.REACT_APP_BLACKBOX_URL, {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify({
      email,
      phone,
      deal: {
        Bank_Account_Owner_Name: data.name,
        Bank_Name: data.bank,
        Bank_Account_Number: data.accountNum,
        CLABE: data.clabe,
        Type_Bank_Account: data.accountType,
        Identifier_Type: data.identiType,
        Identifier_Number: data.identiNum,
        URL_Third_Party: urlTrhidParty,
      },
    }),
  })

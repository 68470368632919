/* eslint-disable consistent-return */
/* eslint-disable complexity */
/* eslint-disable no-negated-condition */
/* eslint-disable max-statements */
import React, { useState } from 'react'
import styles from './styles.module.css'
import Input from '../Input'
import Button from '../Button'
import Select from '../Select'
import {
  BANKS_LIST,
  TYPES_ACCOUNT,
  TYPES_IDENTIFICATIONS,
  NUMERICAL,
  ALPHACNUMERIC,
  TYPES_IDENTIFICATIONS_CL,
  ALPHANUMERIC_WITH_CHARACTERS,
  TYPES_IDENTIFICATIONS_PE
} from '../../util/Constants'
import {
  EMPTY_STRING,
  SELECT_BANK,
  SELECT_ACCOUNT_TYPE,
  SELECT_IDENTIFICACITON_TYPE,
  ERROR_ALPHANUMERIC,
  ERROR_NUMERIC,
  ERROR_IDENTIFICATION_NUMBER,
} from './Constants'
import { Check } from '../Check'
import { ThirdParty } from '../ThirdParty'

/**
 *
 * @param {Object} props -. react props
 * @param {Event} event -. the event 
 * @param {String} country -. the country of the page
 * @param {String} dealName -. the name of the deal
 * @returns {React.Component} the react component 
 */
const Form = ({ event, country, dealName }) => {
  const [name, setName] = useState(EMPTY_STRING)
  const [nameValid, setNameValid] = useState()
  const [bank, setBank] = useState(SELECT_BANK)
  const [bankValid, setBankValid] = useState(false)
  const [accountNum, setAccountNum] = useState(EMPTY_STRING)
  const [accountNumValid, setAccountNumValid] = useState(false)
  const [clabe, setClabe] = useState(EMPTY_STRING)
  const [clabeValid, setClabeValid] = useState(false)
  const [accountType, setAccountType] = useState(SELECT_ACCOUNT_TYPE)
  const [accountTypeValid, setAccountTypeValid] = useState()
  const [identiType, setIdentiType] = useState(SELECT_IDENTIFICACITON_TYPE)
  const [identiTypeValid, setIdentiTypeValid] = useState()
  const [identiNum, setIdentiNum] = useState(EMPTY_STRING)
  const [identiNumValid, setIdentiNumValid] = useState()
  const [identiNumError, setIdentiNumError] = useState(EMPTY_STRING)
  const [isThirdParty, setisThirdParty] = useState(false)
  const [thirdPartyValid, setThirdPartyValid] = useState(false)
  const [path, setPath] = useState(EMPTY_STRING)
  const [interbankCode, setInterbankCode] = useState(EMPTY_STRING)
  const [interbankCodeValid, setInterbankCodeValid] = useState(false)

  /**
   *
   * @param {String} value - the name value
   * @returns {Void} -.
   */
  const validateName = (value) => {
    if (value.charAt(0) !== ' ') {
      const nameArray = value.split(' ')
      if (nameArray.length > 1) {
        return false
      }
      return true
    }
    return true
  }

  /**
   *
   * @param {String} value - the value to capitalize
   * @returns {Void} -.
   */
  const capitalizeFirst = (value) => {
    const array = value.split(' ')
    const capitalize = array
      .map(str => str.charAt(0).toUpperCase() + str.slice(1))
      .join(' ')
    return capitalize
  }

  /**
   *
   * @param {String} value -. the bank value
   * @returns {Void} -.
   */
  const validateBank = (value) => {
    if (value !== SELECT_BANK) {
      setBankValid(false)
      setBank(value)
    } else {
      setBankValid(true)
    }
  }

  /**
   *
   * @param {String} value -. the account num value
   * @returns {Void} -.
   */
  const validateAccountNum = (value) => {
    const re = /^\d+$/
    if (value.match(re) && value.length > 5) {
      return false
    }
    return true
  }

  /**
   *
   * @param {String} value -. the clabe value
   *  @returns {Void} -.
   */
  const validateClabe = (value) => {
    const re = /^\d{18}$/
    if (value.match(re)) {
      return false
    }
    return true
  }

  /**
   *
   * @param {String} value -. the acount type value
   *  @returns {Void} -.
   */
  const validateAccountType = (value) => {
    if (value !== SELECT_ACCOUNT_TYPE) {
      setAccountTypeValid(false)
      setAccountType(value)
    } else {
      setAccountTypeValid(true)
    }
  }

  /**
   *
   * @param {String} value -. the identity type value
   *  @returns {Void} -.
   */
  const validateIdentiType = (value) => {
    if (value !== SELECT_IDENTIFICACITON_TYPE) {
      setIdentiTypeValid(false)
      setIdentiType(value)
    } else {
      setIdentiTypeValid(true)
    }
  }

  /**
   *
   * @param {String} value -. the InterbankCode value
   *  @returns {Void} -.
   */
  const validateInterbankCode = (value) => {
    const re = /^\d{20}$/
    if (value.match(re)) {
      return false
    }
    return true
  }

  /**
   *
   * @param {String} value - the validate identity number
   *  @returns {Void} -.
   */
  const validateIdentiNum = (value) => {
    let re
    if (ALPHACNUMERIC.includes(identiType)) {
      re = /^[0-9a-zA-Z]+$/
      setIdentiNumError(ERROR_ALPHANUMERIC)
    } else if (ALPHANUMERIC_WITH_CHARACTERS.includes(identiType)) {
      re = /^[0-9a-zA-Z_,-]+$/
      setIdentiNumError(ERROR_ALPHANUMERIC)
    } else if (NUMERICAL.includes(identiType)) {
      re = /^\d+$/
      setIdentiNumError(ERROR_NUMERIC)
    } else {
      setIdentiNumValid(true)
      setIdentiNumError(ERROR_IDENTIFICATION_NUMBER)
      return false
    }

    if (value.length > 0 && value.match(re)) {
      setIdentiNumValid(false)
      setIdentiNum(value)
    } else {
      setIdentiNumValid(true)
    }
  }

  /**
   *
   * @param {String} value - the value to set
   * @returns {Void} .
   */
  const handleName = (value) => {
    setName(value)
  }

  /**
   *
   * @param {String} value - the value to set
   * @returns {Void} -.
   */
  const handleCLABE = (value) => {
    setClabe(value)
  }

  /**
   *
   * @param {String} value - the value to set
   * @returns {Void} -.
   */
  const handleAccountNumber = (value) => {
    setAccountNum(value)
  }

  /**
   *
   * @param {String} value - the value to set
   * @returns {Void} -.
   */
  const handleInterbankCode = (value) => {
    setInterbankCode(value)
  }

  /**
   *
   * @param {String} filePath - the file path
   * @returns {Void} -.
   */
  const validateFile = (filePath) => {
    setPath(filePath)
    setThirdPartyValid(false)
  }

  /**
   *
   * @param {Boolean} isAccountNumberValid - is valid
   * @param {Boolean} thirdParty - thirdParty
   * @returns {Void} -.
   */
  const validateMandatoryDataForChile = (
    isAccountNumberValid,
    thirdParty
  ) => {
    return nameValid === false
      && (bankValid === false && bank !== SELECT_BANK)
      && isAccountNumberValid === false
      && (accountTypeValid === false && accountType !== SELECT_ACCOUNT_TYPE)
      && identiTypeValid === false
      && identiNumValid === false
      && (!isThirdParty || (isThirdParty && thirdParty === false))
  }

  /**
   *
   * @param {Boolean} isAccountNumberValid - is valid
   * @param {Boolean} thirdParty - thirdParty
   * @returns {Void} -.
   */
  const validateMandatoryDataForPeru = (
    isAccountNumberValid,
    isInterbankCode,
    thirdParty
  ) => {
    return nameValid === false
      && (bankValid === false && bank !== SELECT_BANK)
      && isAccountNumberValid === false
      && isInterbankCode === false
      && (accountTypeValid === false && accountType !== SELECT_ACCOUNT_TYPE)
      && identiTypeValid === false
      && identiNumValid === false
      && (!isThirdParty || (isThirdParty && thirdParty === false))
  }

  /**
   *  @returns {Void} -.
   */
  const sendData = async () => {
    let fileThirdParty = null
    let thirdParty = false
    if (isThirdParty) {
      if (path?.name) {
        fileThirdParty = isThirdParty && path
      } else {
        thirdParty = true
      }
    } else {
      thirdParty = false
    }
    const isNameValid = validateName(name)
    if (country === 'México') {
      const isAccountNumberValid = validateAccountNum(accountNum)
      const isClabeValid = validateClabe(clabe)
      if (
        isNameValid === false
        && (bankValid === false && bank !== SELECT_BANK)
        && isAccountNumberValid === false
        && isClabeValid === false
        && (!isThirdParty || (isThirdParty && thirdParty === false))
      ) {
        const capitalName = capitalizeFirst(name)
        event({
          name: capitalName,
          bank,
          accountNum,
          clabe,
          fileThirdParty
        })
      } else {
        if (isNameValid !== false) {
          setNameValid(true)
        } else {
          setNameValid(false)
        }
        if (bankValid !== false || bank === SELECT_BANK) {
          setBankValid(true)
        } else {
          setBankValid(false)
        }
        if (isAccountNumberValid !== false) {
          setAccountNumValid(true)
        } else {
          setAccountNumValid(false)
        }
        if (isClabeValid !== false) {
          setClabeValid(true)
        } else {
          setClabeValid(false)
        }
        if (thirdParty !== false) {
          setThirdPartyValid(true)
        } else {
          setAccountTypeValid(false)
        }
      }
    } else if (country === 'Colombia') {
      const isAccountNumberValid = validateAccountNum(accountNum)
      if (
        nameValid === false
        && (bankValid === false && bank !== SELECT_BANK)
        && isAccountNumberValid === false
        && accountTypeValid === false
        && identiTypeValid === false
        && identiNumValid === false
        && (!isThirdParty || (isThirdParty && thirdParty === false))

      ) {
        const capitalName = capitalizeFirst(name)
        event({
          name: capitalName,
          bank,
          accountNum,
          accountType,
          identiType,
          identiNum,
          fileThirdParty,
        })
      } else {
        if (isNameValid !== false) {
          setNameValid(true)
        } else {
          setNameValid(false)
        }
        if (bankValid !== false || bank === SELECT_BANK) {
          setBankValid(true)
        } else {
          setBankValid(false)
        }
        if (isAccountNumberValid !== false) {
          setAccountNumValid(true)
        } else {
          setAccountNumValid(false)
        }
        if (accountTypeValid !== false) {
          setAccountTypeValid(true)
        } else {
          setAccountTypeValid(false)
        }
        if (identiTypeValid !== false) {
          setIdentiTypeValid(true)
        } else {
          setIdentiTypeValid(false)
        }
        if (identiNumValid !== false) {
          validateIdentiNum(EMPTY_STRING)
          setIdentiNumValid(true)
        }
        if (thirdParty !== false) {
          setThirdPartyValid(true)
        } else {
          setAccountTypeValid(false)
        }
      }
    } else if (country === 'Chile') {
      const isAccountNumberValid = validateAccountNum(accountNum)
      const validData = validateMandatoryDataForChile(
        isAccountNumberValid,
        thirdParty)
      if (validData) {
        const capitalName = capitalizeFirst(name)
        event({
          name: capitalName,
          bank,
          accountNum,
          accountType,
          identiType,
          identiNum,
          fileThirdParty
        })
      } else {
        setNameValid(isNameValid)
        setBankValid(bankValid !== false || bank === SELECT_BANK)
        setAccountNumValid(isAccountNumberValid)
        setAccountTypeValid(accountTypeValid)
        setIdentiTypeValid(identiTypeValid)
        if (identiNumValid !== false) {
          validateIdentiNum(EMPTY_STRING)
          setIdentiNumValid(true)
        }
        if (thirdParty !== false) {
          setThirdPartyValid(true)
        } else {
          setAccountTypeValid(false)
        }
        setAccountTypeValid(accountTypeValid !== false)
        setIdentiTypeValid(identiTypeValid !== false)
      }
    } else if (country === 'Peru') {
      const isAccountNumberValid = validateAccountNum(accountNum)
      const isInterbankCodeValid = validateInterbankCode(interbankCode)
      const validData = validateMandatoryDataForPeru(
        isAccountNumberValid,
        isInterbankCodeValid,
        thirdParty)
      if (validData) {
        const capitalName = capitalizeFirst(name)
        event({
          name: capitalName,
          bank,
          accountNum,
          clabe: interbankCode,
          accountType,
          identiType,
          identiNum,
          fileThirdParty
        })
      } else {
        setNameValid(isNameValid)
        setBankValid(bankValid !== false || bank === SELECT_BANK)
        setAccountNumValid(isAccountNumberValid)
        setAccountTypeValid(accountTypeValid)
        setIdentiTypeValid(identiTypeValid)
        if (identiNumValid !== false) {
          validateIdentiNum(EMPTY_STRING)
          setIdentiNumValid(true)
        }
        if (thirdParty !== false) {
          setThirdPartyValid(true)
        } else {
          setAccountTypeValid(false)
        }
        setInterbankCodeValid(isInterbankCodeValid !== false)
        setAccountTypeValid(accountTypeValid !== false)
        setIdentiTypeValid(identiTypeValid !== false)
      }
    }
  }

  return (
    <div className={styles.Container}>
      <div className={styles.BankData}>Mis datos bancarios</div>
      <div className={styles.InputsContainer}>
        <div className={styles.FormElemnt}>
          <Input
            label="Nombre completo del titular de la cuenta"
            error="Ingresa el nombre completo del titular de la cuenta"
            onChange={handleName}
            showError={nameValid}
            type="text"
          />
        </div>
        <div className={styles.FormElemnt}>
          <Select
            data={BANKS_LIST[country]}
            event={validateBank}
            label="Banco"
            error={SELECT_BANK}
            showError={bankValid}
          />
        </div>
        <div className={styles.FormElemnt}>
          <Input
            label="Número de cuenta"
            error="Ingresa un número de cuenta valido"
            onChange={handleAccountNumber}
            showError={accountNumValid}
            type="text"
            value={accountNum}
            isNumber
          />
        </div>
        {country === 'México' && (
          <div className={styles.FormElemnt}>
            <Input
              label="Cuenta Clabe"
              error="Ingresa los 18 digitos de tu cuenta clabe"
              onChange={handleCLABE}
              showError={clabeValid}
              type="text"
              maxLength={18}
              isNumber
              value={clabe}
            />
          </div>
        )}

        {country === 'Colombia' && (
          <>
            <div className={styles.FormElemnt}>
              <Select
                data={TYPES_ACCOUNT}
                event={validateAccountType}
                label="Tipo de cuenta"
                error="Selecciona un tipo de cuenta"
                showError={accountTypeValid}
              />
            </div>
            <div className={styles.FormElemnt}>
              <Select
                data={TYPES_IDENTIFICATIONS}
                event={validateIdentiType}
                label="Tipo de Identificación"
                error="Selecciona un tipo de identificación"
                showError={identiTypeValid}
              />
            </div>
            <div className={styles.FormElemnt}>
              <Input
                label="Número de Identificación"
                error={identiNumError}
                onChange={validateIdentiNum}
                showError={identiNumValid}
                type="text"
              />
            </div>
          </>
        )}

        {country === 'Chile' && (
          <>
            <div className={styles.FormElemnt}>
              <Select
                data={TYPES_ACCOUNT}
                event={validateAccountType}
                label="Tipo de cuenta"
                error="Selecciona un tipo de cuenta"
                showError={accountTypeValid}
              />
            </div>
            <div className={styles.FormElemnt}>
              <Select
                data={TYPES_IDENTIFICATIONS_CL}
                event={validateIdentiType}
                label="Tipo de Identificación"
                error="Selecciona un tipo de identificación"
                showError={identiTypeValid}
              />
            </div>
            <div className={styles.FormElemnt}>
              <Input
                label="Número de Identificación"
                error={identiNumError}
                onChange={validateIdentiNum}
                showError={identiNumValid}
                type="text"
              />
            </div>
          </>
        )}
        {country === 'Peru' && (
          <>
            <div className={styles.FormElemnt}>
              <Input
                label="Código de cuenta interbancario"
                error="Ingresa los 20 digitos de tu cuenta clabe"
                onChange={handleInterbankCode}
                showError={interbankCodeValid}
                type="text"
                maxLength={20}
                isNumber
                value={interbankCode}
              />
            </div>
            <div className={styles.FormElemnt}>
              <Select
                data={TYPES_ACCOUNT}
                event={validateAccountType}
                label="Tipo de cuenta"
                error="Selecciona un tipo de cuenta"
                showError={accountTypeValid}
              />
            </div>
            <div className={styles.FormElemnt}>
              <Select
                data={TYPES_IDENTIFICATIONS_PE}
                event={validateIdentiType}
                label="Tipo de Identificación"
                error="Selecciona un tipo de identificación"
                showError={identiTypeValid}
              />
            </div>
            <div className={styles.FormElemnt}>
              <Input
                label="Documento de identidad"
                error={identiNumError}
                onChange={validateIdentiNum}
                showError={identiNumValid}
                type="text"
              />
            </div>
          </>
        )}
        <Check name={dealName} event={(value) => setisThirdParty(value)} />

        {isThirdParty && <ThirdParty country={country} event={validateFile} />}

        {isThirdParty && thirdPartyValid && (
          <div className={styles.Error}>Selecciona un archivo para cargar</div>
        )}

        <div className={styles.ButtonContainer}>
          <Button event={sendData}>Enviar</Button>
        </div>
      </div>
    </div>
  )
}

export default Form

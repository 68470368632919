import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import Success from './Pages/Success/index'
import NotFound from './Pages/NotFound'
import Home from './Pages/Home'

/**
 * App main component.
 * @returns {void} .
 */
const App = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/success" render={() => <Success />} />
      <Route exact path="/not-found" render={() => <NotFound />} />
      <Route path="/:publicKey" render={() => <Home />} />
    </Switch>
  </BrowserRouter>
)

export default App

import React from 'react'
import styles from './styles.module.css'
import logo from '../../Assets/images/svg/logo.svg'

/**
 * Header component.
 * @returns {void} .
 */
const Header = () => (
  <header className={styles.MainContainer}>
    <div className={styles.Logo}>
      <img alt="logo" src={logo} />
    </div>
  </header>
)
export default Header

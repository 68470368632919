/* eslint-disable no-process-env */
/* eslint-disable import/prefer-default-export */
/* eslint-disable implicit-arrow-linebreak */
import { callApi, basicAuthHeader } from '../util/Requests'

/**
 * funtion to make a request to DB sending a custom JSON
 * @param {string} publicKey - element to search in DB
 * @returns {Object} Patient Object
 */
export const getPatient = (publicKey) =>
  callApi(process.env.REACT_APP_GET_DATA, {
    headers: basicAuthHeader(),
    method: 'POST',
    body: JSON.stringify({
      table: 'Patient',
      columns: {
        PublicKey: publicKey,
      },
    }),
  })

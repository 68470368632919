import React, { useState } from 'react'
import styles from './styles.module.css'
import { FILE_THIRD_PARTY } from '../../util/Constants'
import imgUpload from '../../Assets/images/png/upload.png'
import imgDownload from '../../Assets/images/png/download.png'
import { EMPTY_STRING } from '../Form/Constants'

export const ThirdParty = ({ country, event }) => {
  const [file, setFile] = useState(EMPTY_STRING)

  const link = FILE_THIRD_PARTY[country]

  const handleFile = async (file) => {
    const startString = file.target.files[0].name.lastIndexOf('.')
    const extension = file.target.files[0].name.substring(startString)
    setFile(file.target.files[0].name.substring(0, 5) + '... ' + extension)
    event(file.target.files[0])
  }

  return (
    <div className={styles.Container}>
      <div className={styles.ImageContainer}>
        <img src={imgDownload} alt="Third Party download" />
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.Button}
        >
          Descargar
        </a>
      </div>
      <div className={styles.ImageContainer} id="load">
        <img src={imgUpload} alt="Third Party upload" />
        <input
          type="file"
          name="file"
          id="file"
          className={styles.Inputfile}
          onChange={handleFile}
        />
        <label htmlFor="file" className={styles.Button}>
          Subir un archivo
        </label>
        {file && <label>{file}</label>}
      </div>
    </div>
  )
}

export const BANKS_LIST = {
  México: [
    'Selecciona un Banco',
    'CITIBANAMEX',
    'BBVA BANCOMER',
    'SANTANDER',
    'HSBC',
    'BANORTE',
    'AMERICAN EXPRESS',
    'SCOTIABANK',
    'AZTECA',
    'BANCOPPEL',
    'BANJERCITO',
    'LIVERPOOL VISA',
    'MIFEL',
    'BANCOMEXT',
    'BANOBRAS',
    'BAJIO',
    'IXE',
    'INBURSA',
    'INTERACCIONES',
    'BANREGIO',
    'INVEX',
    'BANSI',
    'AFIRME',
    'THE ROYAL BANK',
    'BAMSA',
    'TOKYO',
    'JP MORGAN',
    'BMONEX',
    'VE POR MAS',
    'ING',
    'DEUTSCHE',
    'CREDIT SUISSE',
    'AUTOFIN',
    'BARCLAYS',
    'COMPARTAMOS',
    'BANCO FAMSA',
    'BMULTIVA',
    'ACTINVER',
    'WAL-MART',
    'NAFIN',
    'INTERBANCO',
    'ABC CAPITAL',
    'UBS BANK ',
    'CONSUBANCO',
    'VOLKSWAGEN',
    'CIBANCO',
    'BBASE',
    'BANSEFI',
    'HIPOTECARIA FEDERAL',
    'MONEXCB',
    'GBM',
    'MASARI',
    'VALUE',
    'ESTRUCTURADORES',
    'TIBER',
    'VECTOR',
    'B&B',
    'ACCIVAL',
    'MERRILL LYNCH',
    'FINAMEX',
    'VALMEX',
    'UNICA',
    'MAPFRE',
    'PROFUTURO',
    'CB ACTINVER',
    'OACTIN',
    'SKANDIA',
    'CBDEUTSCHE',
    'ZURICH',
    'ZURICHVI',
    'SU CASITA',
    'CB INTERCAM',
    'CI BOLSA',
    'BULLTICK CB',
    'STERLING',
    'FINCOMUN',
    'HDI SEGUROS',
    'ORDER',
    'AKALA',
    'CB JPMORGAN ',
    'REFORMA',
    'STP',
    'TELECOMM',
    'EVERCORE',
    'SKANDIA',
    'SEGMTY',
    'ASEA',
    'KUSPIT',
    'SOFIEXPRESS',
    'UNAGRA',
    'OPCIONES EMPRESARIALES DEL NOROESTE',
    'CLS',
    'INDEVAL',
    'LIBERTAD',
    'Oyster'
  ],
  Colombia: [
    'Selecciona un Banco',
    'Bancolombia',
    'Banco de Bogotá',
    'Banco Davivienda',
    'Banco BBVA',
    'Banco AV Villas',
    'Banco BCSC',
    'Red Multibanca Colpatria',
    'NEQUI',
    'Banco de Occidente',
    'Banco de la República',
    'Banco Popular',
    'Banco Itaú Corpbanca Colombia S.A. ',
    'Banco Citibank',
    'Banco GNB Sudameris',
    'Bancoldex',
    'Banco Agrario de Colombia',
    'Jp Morgan',
    'BNP Paribas',
    'Banco Procredit Colombia',
    'Banco Pichincha',
    'Bancoomeva',
    'Banco Serfinanza',
    'Banco Falabella',
    'Banco Finandina',
    'Banco Santander de Negocios Colombia S.A.',
    'Banco Coopcentral',
    'Banco Compartir',
    'Compensar',
    'Aportes en Línea',
    'Asopagos',
    'Fedecajas',
    'Simple S.A.',
    'Enlace Operativo S.A.',
    'Financiera Juriscoop',
    'Cooperativa Financiera de Antioquia',
    'Cooperativa Financiera Cotrafa',
    'Confiar Cooperativa Financiera',
    'Coltefinanciera',
    'Deceval',
    'Dirección del Tesoro Nacional',
    'Dirección del Tesoro Nacional  - Regalias',
    'Banco Mundo Mujer',
    'Scotiabank Colpatria',
    'Banco Caja Social',
    'Banco Cooperativo Coopcentral',
    'Banco Itau',
    'Banco Pichincha s.a',
    'CFA Cooperativa Financiera',
    'RAPPIPAY',
  ],
  Chile: [
    'Selecciona un Banco',
    'BANCO DE CHILE',
    'BANCO INTERNACIONAL',
    'SCOTIABANK CHILE',
    'BANCO DE CREDITO E INVERSIONES',
    'BANCO BICE',
    'HSBC BANK (CHILE)',
    'BANCO SANTANDER-CHILE',
    'ITAÚ CORPBANCA',
    'BANCO SECURITY',
    'BANCO FALABELLA',
    'BANCO RIPLEY',
    'BANCO CONSORCIO',
    'SCOTIABANK AZUL',
    'BANCO BTG PACTUAL CHILE',
    'BANCO DEL ESTADO DE CHILE'
  ],
  Peru: [
    'Selecciona un Banco',
    'BANCO DE COMERCIO',
    'BANCO DE CRÉDITO DEL PERÚ',
    'BANCO INTERAMERICANO DE FINANZAS (BANBIF)',
    'BANCO PICHINCHA',
    'BBVA',
    'CITIBANK PERÚ',
    'INTERBANK',
    'MIBANCO',
    'SCOTIABANK PERÚ',
    'BANCO GNB PERÚ',
    'BANCO FALABELLA',
    'BANCO RIPLEY',
    'BANCO SANTANDER PERÚ',
    'ALFIN BANCO',
    'BANK OF CHINA',
    'ICBC PERU BANK',
    'AGROBANCO',
    'BANCO DE LA NACIÓN'
  ]
}

export const TYPES_ACCOUNT = [
  'Selecciona un tipo de cuenta',
  'Ahorro',
  'Corriente',
]

export const TYPES_IDENTIFICATIONS = [
  'Selecciona un tipo de identificación',
  'Cédula de ciudadanía',
  'Cédula de extranjería',
  'Número de Identificación Tributario',
  'Tarjeta de Identidad',
  'Pasaporte',
  'Identificador único de cliente',
  'Número móvil',
  'Registro civil',
  'Documento de identificación extranjero',
]

export const NUMERICAL = [
  'Cédula de ciudadanía',
  'Tarjeta de Identidad',
  'Número de Identificación Tributario',
  'Número móvil',
  'Registro civil',
]

export const ALPHACNUMERIC = [
  'Pasaporte',
  'Documento de identificación extranjero',
  'Cédula de extranjería',
  'Identificador único de cliente',
  'Cédula de ciudadanía',
  'Carnet de Identidad',
  'Carnet de Extranjería'
]

export const ALPHANUMERIC_WITH_CHARACTERS = [
  'RUT',
  'DNI',
  'RUC'
]

export const FILE_THIRD_PARTY = {
  Colombia: process.env.REACT_APP_FILE_THIRD_PARTY_CO,
  México: process.env.REACT_APP_FILE_THIRD_PARTY_MX,
  Chile: process.env.REACT_APP_FILE_THIRD_PARTY_CL,
  Peru: process.env.REACT_APP_FILE_THIRD_PARTY_PE
}

export const TYPES_IDENTIFICATIONS_CL = [
  'Selecciona un tipo de identificación',
  'RUT',
  'Cédula de ciudadanía',
  'Cédula de extranjería',
  'Pasaporte',
  'Carnet de Identidad'
]

export const TYPES_IDENTIFICATIONS_PE = [
  'Selecciona un tipo de identificación',
  'DNI',
  'RUC',
  'Carnet de Extranjería',
  'Pasaporte'
]

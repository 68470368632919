/* eslint-disable no-negated-condition */
/* eslint-disable max-statements */
import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import styles from './styles.module.css'
import Shell from '../../Components/Shell'
import LoaderFullScreen from '../../Components/LoaderFullScreen'
import Title from '../../Components/Title'
import Form from '../../Components/Form'
import Modal from '../../Components/Modal'
import { getPatient } from '../../Apis/DB'
import { updatePatientBlackBox } from '../../Apis/BlackBox'
import { updateTransactionLog } from '../../Apis/Refund'
import { uploadFile } from '../../Apis/Files'
import { sendEmail } from '../../Apis/Email'

/**
 * Home page view
 * @return {void}
 */
const Home = () => {
  const history = useHistory()
  const { publicKey } = useParams()
  const [showLoader, setShowLoader] = useState(true)
  const [modalOpen, setModalOpen] = useState(false)
  const [messageLoader, setMessageLoader] = useState(
    'Estamos configurando todo'
  )
  const [dataPatient, setDataPatient] = useState()
  const [data, setData] = useState()

  useEffect(() => {
    /**
     * Get Patient data, if this exist set the info in setDataPatient
     * else redirect to /not-found view
     * @return {void}
     */
    const fetchData = async () => {
      const response = await getPatient(publicKey)
      if (response?.detail === 'data not found') {
        history.push('/not-found')
      } else {
        setDataPatient(response.Patient)
        setShowLoader(false)
      }
    }
    fetchData()
  }, [publicKey, history])

  /**
   * Set the info from Form insetData and show modal
   * @param {Object}  values - Object with the info that was given in Form
   * @return {void}
   */
  const handleModal = (values) => {
    setData(values)
    setModalOpen(true)
  }

  /**
   * Send to CRM the data, if register is SUCCESS then redirect to "/success"
   * page else redirect to "/not-found"
   * @return {void}
   */
  const handleSendToCRM = async () => {
    setMessageLoader('Realizando operación')
    setShowLoader(true)
    let urlTrhidParty = ''

    if (data.fileThirdParty) {
      urlTrhidParty = await uploadFile(
        data.fileThirdParty,
        dataPatient.Deal_Name,
        publicKey
      )
      if (urlTrhidParty.status === 'Error') {
        history.push('/not-found', {
          status: 'Error 404',
          desc: urlTrhidParty.desc,
        })
        return false
      }
    }

    const responseBlackBox = await updatePatientBlackBox(
      dataPatient.Phone,
      dataPatient.Email,
      data,
      urlTrhidParty
    )

    if (responseBlackBox?.Deal?.code === 'SUCCESS') {
      const responseEmail = await sendEmail(
        dataPatient.Email,
        dataPatient.Country_Ops,
        data,
        dataPatient.CustomerId
      )
      if (responseEmail.sucess) {
        const { transactionLog, error } = await updateTransactionLog({
          customerId: dataPatient.CustomerId
        })
        history.push('/success', { country: dataPatient.Country_Ops })
      } else {
        history.push('/not-found', {
          status: 'Error 404',
          desc: 'Error al enviar correo de confirmación',
        })
      }
    } else {
      history.push('/not-found', {
        status: 'Error 404',
        desc: responseBlackBox.message,
      })
    }
  }

  if (showLoader) {
    return <LoaderFullScreen message={messageLoader} />
  }

  return (
    <Shell>
      {modalOpen && (
        <Modal
          data={data}
          country={dataPatient.Country_Ops}
          close={() => setModalOpen(false)}
          confirm={handleSendToCRM}
        />
      )}
      <div className={styles.Container}>
        <div className={styles.TitleContainer}>
          <Title>Necesitamos tu ayuda para hacer la devolución</Title>
        </div>
        <div className={styles.FormContainer}>
          <Form
            event={handleModal}
            country={dataPatient.Country_Ops}
            dealName={dataPatient.Deal_Name}
          />
        </div>
      </div>
    </Shell>
  )
}

export default Home

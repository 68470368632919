import React from 'react'
import styles from './styles.module.css'
import imgMoon from '../../Assets/images/svg/moon.svg'
import imgLogo from '../../Assets/images/svg/logo-white.svg'
import { useHistory } from 'react-router-dom'

/**
 * Not Found page view.
 * @returns {void} .
 */
const NotFound = () => {
  const history = useHistory()
  let status = 'Error 404'
  let desc = 'Usuario no encontrado'
  console.log(history.location.state)
  if (history.location.state) {
    status = history.location.state.status
    desc = history.location.state.desc
  }

  return (
    <div className={styles.Container}>
      <div className={styles.LogoContainer}>
        <img src={imgLogo} alt="Moons" />
      </div>
      <div className={styles.MessageContainer}>
        <h1 className={styles.Title}>{status}</h1>
        <div className={styles.Text}>Parece que esta luna está abandonada.</div>
        <div className={styles.TextGrey}>({desc})</div>
      </div>
      <div className={styles.MoonContainer}>
        <img src={imgMoon} className={styles.ImageMoon} alt="not-found" />
      </div>
    </div>
  )
}

export default NotFound

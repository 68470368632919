/* eslint-disable react/jsx-no-literals */
/* eslint-disable react/jsx-handler-names */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react'
import arrow from '../../Assets/images/svg/arrow_down.svg'
import styles from './styles.module.css'

/**
 * Select Component
 * @returns {React.Component} Select .
 */
const Select = ({ data, label, event, error, showError }) => {
  const [current, setCurrent] = useState(0)
  const [openSelect, setOpenSelect] = useState(false)

  const changeValue = (item) => {
    setCurrent(item)
    event(data[item])
  }

  return (
    <div className={styles.DropdownContainer}>
      <label className={styles.Label}>{label}</label>
      <div className={styles.ThemesMobileContainer}>
        <div
          className={styles.TopicSelector}
          onClick={() => setOpenSelect(!openSelect)}
        >
          <div className={styles.TextTema}>
            {data[current]}
            <img
              alt="flecha"
              src={arrow}
              style={{ transform: `rotate(${openSelect ? 0 : 180}deg)` }}
            />
          </div>
          {openSelect && (
            <ul className={styles.Scroll}>
              {data.map((elem, index) => {
                if (current !== index) {
                  return (
                    <li
                      key={index}
                      onClick={() => changeValue(index)}
                      className={styles.Items}
                    >
                      {elem}
                    </li>
                  )
                }
                return null
              })}
            </ul>
          )}
        </div>
      </div>
      {showError && <span className={styles.ErrorMessage}>{error}</span>}
    </div>
  )
}

export default Select

/* eslint-disable lines-around-comment */
/* eslint-disable no-return-await */
/* eslint-disable valid-jsdoc */
/* eslint-disable import/prefer-default-export */
import axios from 'axios'

/**
 * funtion to seng file to s3
 * @param {object} file - File selected
 * @param {string} name - Deal_Name
 * @returns {string || Object} string with url or error object
 */
export const uploadFile = async (file, name, publicKey) => {
  const data = new FormData()
  data.append('File', file)
  data.append('bucketName', process.env.REACT_APP_BUCKET)
  data.append('isPublic', 'true')
  data.append('folderName', 'refunds-test/third-parties')
  data.append('fileName', `${name}-${publicKey}-third-party`)

  const config = {
    method: 'post',
    url: process.env.REACT_APP_SET_FILE,
    headers: { 'Content-Type': 'multipart/form-data' },
    data,
  }
  return await axios(config)
    .then((responseUpload) => responseUpload.data.file_url)
    .catch((error) => {
      console.log(error)
      return { status: 'Error', desc: 'Error al subir el archivo' }
    })
}

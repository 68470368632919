/* eslint-disable no-process-env */

/**
 * funtion to make a request
 * @param {string} url - url to create a request
 * @param {Object} options - Body request
 * @returns {Object} return object
 */
export const callApi = async (url, options) => {
  const response = await fetch(url, options)
  return response.json()
}

/**
 * funtion to create a Basic Authorization Header
 * @returns {Object} {Authorization, Content-Type}
 */
export const basicAuthHeader = () => {
  const user = process.env.REACT_APP_BASIC_AUTH_USERNAME
  const password = process.env.REACT_APP_BASIC_AUTH_PASSWORD
  const auth = `${user}:${password}`
  const authEncoded = `Basic ${Buffer.from(auth).toString('base64')}`
  return {
    Authorization: authEncoded,
    'Content-Type': 'application/json',
  }
}

/* eslint-disable no-process-env */
/* eslint-disable import/prefer-default-export */
/* eslint-disable implicit-arrow-linebreak */
import { callApi } from '../util/Requests'
const countryList = {
  'México': 'MX',
  Colombia: 'CO',
  Chile: 'CL',
  Peru: 'PE'
}

/**
 * funtion to make a request to BlackBox sending a custom JSON
 * @param {string} phone - element to search in BlackBox
 * @param {string} email - element to search in BlackBox
 * @param {Object} data -
 * {Bank_Name,Bank_Account_Number,CLABE,Type_Bank_Account, Identifier_Type,Identifier_Number}
 * @returns {Object} BlackBox Result Object
 */
export const sendEmail = (email, country, data, customerId) =>
  callApi(`${process.env.REACT_APP_REFUND_API}/refunds/request-bank-data`, {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify({
      toMail: email,
      countryOps: country,
      type: 'bankAccountConfirmation',
      bankName: data.bank,
      accountName: data.name,
      bankAccount: data.accountNum,
      accountType: data.accountType,
      clabe: data.clabe,
      idType: data.identiType,
      idNumber: data.identiNum,
      eventType: `Bank-Account-Confirmation-${countryList[country]}`,
      customerId
    }),
  })
